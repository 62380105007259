$media-border-color: bulmaRgba($border, 0.5) !default;
$media-spacing: 1rem;
$media-spacing-large: 1.5rem;

.media {
  align-items: flex-start;
  display: flex;
  text-align: inherit;
  .content:not(:last-child) {
    margin-bottom: 0.75rem; }
  .media {
    border-top: 1px solid $media-border-color;
    display: flex;
    padding-top: 0.75rem;
    .content:not(:last-child),
    .control:not(:last-child) {
      margin-bottom: 0.5rem; }
    .media {
      padding-top: 0.5rem;
      & + .media {
        margin-top: 0.5rem; } } }
  & + .media {
    border-top: 1px solid $media-border-color;
    margin-top: $media-spacing;
    padding-top: $media-spacing; }
  // Sizes
  &.is-large {
    & + .media {
      margin-top: $media-spacing-large;
      padding-top: $media-spacing-large; } } }

.media-left,
.media-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0; }

.media-left {
  @include ltr-property("margin", $media-spacing); }

.media-right {
  @include ltr-property("margin", $media-spacing, false); }

.media-content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: inherit; }

.payment-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .fa-edit {
    cursor: pointer; } }

.payment-status {
  display: flex;
  align-items: center; }

.payment-content {
  margin-right: 1.5rem;
  margin-top: 0.6rem;
  font-style: italic;
  font-weight: 600;
  font-size: 13px; }

.bank-account-note {
  p {
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
    text-align: justify; } }

.empty-bank-details {
  color: red;
  font-size: 0.8rem !important; }

.filled-bank-details {
  color: green;
  font-size: 0.8rem !important; }

.pending-bank-details {
  color: orange;
  font-size: 0.8rem !important; }

.w9-form-status {
  p {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 600; } }

.ssn-masked, .ein-masked {
  -webkit-appearance: none;
  width: 100%;
  max-width: 100%;
  align-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: inset 0 0.0625em 0.125em rgba(31, 31, 31, 0.05);
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;

  &::placeholder {
    opacity: 0.7; }

  &:hover {
    border-color: #b5b5b5; }

  &:focus {
    outline: none;
    border: 1px solid #b9995b;
    box-shadow: 0 0 0 0.125em rgba(185, 153, 91, 0.25); } }

.w9-terms-of-service {
  font-size: 14px !important; }

.bank-account-errors {
  color: red;
  margin-bottom: 8px; }

@include mobile {
  .media-content {
    overflow-x: auto; } }
