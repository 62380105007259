@import '../../theme/assets/styles/bulma/sass/utilities/_all';
@import '../../../_variables';



.modal-content-wrap {
    position: relative;
    margin-top: 100px !important;
    margin-bottom: 1rem !important;
    @include mobile {
        margin-top: 40px !important; } }

.modal-content-wrap .delete.modal-close-x {
    display: block; }

.payment-confirm-screen.modal-open .delete.modal-close-x {
    display: block; }

.modal-close-floating {
    position: absolute !important;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 99999; }

.modal-with-tabs {
    @include mobile {
        top: unset !important;
        bottom: -2.5rem !important;
        right: 0.75rem; } }

.modal-shell {
    position: relative; }

.modal-header-wrapper h2 {
    margin-right: 1.5rem;
    color: #000000; }

.modal-header-wrapper .delete.modal-close-x {
    position: absolute !important;
    top: 1.25rem;
    right: 1.25rem; }

.modal-content-wrap.is-fullscreen-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    position: absolute;
    max-height: none; }

.modal-box.is-fullscreen-modal {
    position: relative;
    min-height: 87%; }

.custom-image-modal {
    min-height: unset !important; }

.modal-box.is-fullscreen-modal .fixed-action {
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.25rem;
    border-top: 2px solid $border-color;
    background-color: #fff;
    & .insurance-cost {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: right;
        color: #212121;
        margin-top: -14px;
        margin-right: -20px; }
    & .flexible-cancellation, .worry-free-guarantee {
        box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.25);
        margin-left: -2.5rem; }
    & .flexible-cancellation {
        margin-bottom: -1rem; } }

.modal-content-wrap.is-fullscreen-modal-height-auto {
    max-width: 85%; }

.is-mini-modal.modal-content {
    @include mobile {
        width: 100%; }
    @include tablet {
        width: 360px; }
    @include desktop {
        width: 360px; }
    @include widescreen {
        width: 360px; }
    @include fullhd {
        width: 360px; } }

.is-tiny-modal.modal-content {
    @include mobile {
        width: 100%; }
    @include tablet {
        width: 540px; }
    @include desktop {
        width: 540px; }
    @include widescreen {
        width: 540px; }
    @include fullhd {
        width: 540px; } }

.is-small-modal.modal-content {
    @include mobile {
        width: 100%; }
    @include tablet {
        width: 720px; }
    @include desktop {
        width: 720px; }
    @include widescreen {
        width: 720px; }
    @include fullhd {
        width: 720px; } }

.is-large-modal.modal-content {
    @include mobile {
        width: 100%; }
    @include tablet {
        width: 1080px; }
    @include desktop {
        width: 1080px; }
    @include widescreen {
        width: 1080px; }
    @include fullhd {
        width: 1080px; } }


//.is-tiny-modal
//.is-small-modal
//.is-large-modal

.is-auto-modal {
    width: auto !important; }
