$primary-button-color: linear-gradient(180deg, #AF985F 0%, #695626 100%) !important;

.is-primary-non-active {
    border: 1px solid #424242 !important;
    color: #000000 !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center !important;
    border-radius: 8px !important; }

.button.is-primary {
    background: $primary-button-color;
    border-top: none;
    border-bottom: none;
    color: #FFFFFF !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center !important;
    border-radius: 8px !important; }

.button.is-primary.is-yellow-btn, .button.is-primary.is-yellow-btn {
    background: linear-gradient(180deg, #FFEC3B 0%, #E2BC00 100%) !important;
    color: #000000 !important;
    border-left: 0;
    border-right: 0; }

.button.is-primary.is-grey-btn, .button.is-primary.is-grey-btn {
    background: #8B8B8B !important;
    border-radius: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF; }

.CalendarMonth_caption strong {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000; }

.CalendarMonth_table tbody tr td {
  padding-top: 7px !important; }

.DayPicker_weekHeader.DayPicker_weekHeader_1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000; }

.filters-bar-buttons {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #424242;
    border: 1px solid #424242;
    box-sizing: border-box;
    border-radius: 8px !important; }

.has-black-dark-text {
    color: #000000 !important; }

.pagination-previous,
.pagination-next {
    background: $primary-button-color; }

// style at confirm and pay page pickup/dropoff time selector
.payment-block .select-full-width select {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border-color: 2px solid #ECECEC !important;
    box-sizing: border-box;
    border-radius: 8px; }

.social-icons .fa-circle {
    color: transparent;
    background: linear-gradient(180deg, #AF985F 0%, #695626 100%);
    border-radius: 50%;
    width: 55px;
    height: 55px;
    margin-top: 3px;
    margin-left: 12px; }

.search-filter-apply-btn button {
    background: linear-gradient(180deg, #FFEC3B 0%, #E2BC00 100%);
    border-radius: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    border: none; }

.switch[type="checkbox"].is-primary:checked + label::before {
    background: $primary-button-color;
    border-top: none;
    border-bottom: none;
    border-radius: 16px; }

.switch[type="checkbox"].is-rounded + label::before {
    background: #BDBDBD !important;
    border-radius: 16px; }

.is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after {
    background: $primary-button-color; }

.is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label:before {
    background: $primary-button-color;
    border-top: 0; }

.rating-stars .twisted-full-star {
    background: -webkit-linear-gradient(180deg, #AF985F 0%, #695626 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.modal-box .button.is-danger.is-outlined {
    width: 75px;
    height: 40px;
    border-radius: 8px; }

.listing-delete-motorcycle.is-danger {
    width: 100% !important;
    height: auto !important; }

.add-card-wrapper label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    color: #000000; }

.default-font {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #000000; }
