.page-master-container {
  min-height: 400px !important;
}

@media only screen and (max-width: 768px) {
  .page-master-container {
    min-height: auto !important;
  }
}

html {
  scroll-behavior: smooth;
}
