@import 'components/theme/assets/styles/bulma/sass/utilities/_all';
@import '_variables';
@import 'updated';

$box-shadow:  0 0.5em 1em -0.125em rgba($scheme-invert, 0.3), 0 0px 0 1px rgba($scheme-invert, 0.02);

/* CONTAINERS */
.container.extra-extra-small, .container.extra-small, .container.small, .container.medium, .container.large {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    @include mobile {
        margin-top: 1rem; } }

.container.extra-extra-small {
    max-width: 550px !important; }

.container.extra-small {
    max-width: 720px !important; }

.container.small {
    max-width: 960px !important; }

.container.medium {
    max-width: 1152px !important; }

.container.large {
    max-width: 1344px !important; }

.hero-has-fill-image-container {
    height: 25.5rem;
    @include mobile {
        height: 17rem; } }

.hero-has-fill-image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 25.5rem;
    @include mobile {
        height: 14rem; } }

.hero-has-fill-image.bottom-position {
    background-position: center bottom; }

.hero-shader {
    background-color: rgba(0,0,0,.65);
    height: 24.5rem;
    @include mobile {
        height: 14rem; } }

.container.extra-small .content.blog-content figure {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }

.container.extra-small .content.blog-content img {
    width: 100%;
    height: auto;
    max-width: none; }

.has-border {
    border: 1px solid #E8E8E8; }

.has-border-y {
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8; }

.has-border-bottom {
    border-bottom: 1px solid #E8E8E8;
    &:last-child {
        border-bottom: none; } }

.has-no-border {
    border: none; }

.hero {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 24.5rem;
    @include mobile {
        height: 16rem;
        .location-page-search-wrapper {
            width: 90%;
            margin-top: 3rem;
            left: 5%; } } }

.location-page-shader {
    background-color: rgba(0,0,0,.65); }

img.is-avatar, img.is-avatar.small {
    border-radius: 290486px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    background-color: transparent;
    margin-right: .25rem;
    height: 2rem;
    width: 2rem;
    display: inline-block;
    border-radius: 500rem; }

img.is-rounded {
    border-radius: 290486px;
    object-fit: cover; }

.is-circular {
    border-radius: 290486px !important; }

img.is-avatar.medium {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem; }

img.is-avatar.large {
    height: 6rem;
    width: 6rem; }

.m-center {
    margin-left: auto;
    margin-right: auto; }

.is-inline-dropdown .button {
    border: 0px;
    outline: 0px;
    padding: 0px !important;
    display: inline;
    height: auto !important; }

.is-inline-dropdown .button .icon {
    width: auto;
    height: auto; }

.hide-mobile {
    @include mobile {
        display: none; } }

.no-border {
    border: none !important; }

body {
    height: 100%;
    width: 100%; }

html,
body {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    position:relative {}
    font-family: 'Open Sans', sans-serif; }

img {
    max-width: 100%;
    height: auto; }

.info-link {
    color: #B9995A; }

/* These calendar things need variables and also they need to be in a different file */
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: linear-gradient(180deg, #AF985F 0%, #695626 100%) !important;
    border: 1px solid #9E9E9E !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF; }

.CalendarDay__selected_span {
    background: linear-gradient(180deg, #AF985F 0%, #695626 100%) !important;
    border: 1px solid #9E9E9E !important; }

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #CFB88C !important;
    border: 1px double #CFB88C !important;
    color: #fff !important; }

.DateInput_input__focused {
    border-bottom: 2px solid #B9995A !important; }

.has-text-weight-extra-bold {
    font-weight: 800 !important; }

.is-full {
    width: 100%; }

.full-modal {
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    max-height: none; }

.modal-container {
    z-index: 99999;
    background-color: #fff; }

.full-modal.no-padding .modal-card-body {
    padding: 0px; }

.modal-card-head.fixed-head {
    position: absolute;
    background-color: transparent;
    border: 0px;
    right: 0px;
    font-size: 2rem; }

.transparent {
    background-color: transparent !important; }

.has-rounded-corners {
    border-radius: 6px; }

.select-full-width, .select-full-width select {

    width: 100%; }

.p-7 {
    padding: 4rem !important; }

.px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }

.py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }

.pt-7 {
    padding-top: 4rem !important; }

.pr-7 {
    padding-right: 4rem !important; }

.pb-7 {
    padding-bottom: 4rem !important; }

.pl-7 {
    padding-left: 4rem !important; }

.m-7 {
    margin: 4rem !important; }

.mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }

.my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }

.mt-7 {
    margin-top: 4rem !important; }

.mr-7 {
    margin-right: 4rem !important; }

.mb-7 {
    margin-bottom: 4rem !important; }

.ml-7 {
    margin-left: 4rem !important; }

.agree-checkbox {
    margin-bottom: 2rem; }

.fixed-button-mobile {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1.25rem;
    border-top: 2px;
    border-top: 2px solid #E8E8E8;
    background-color: #fff; }

.has-image-centered {
    margin-left: auto;
    margin-right: auto; }

nav.scrolling-mobile-menu {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem; }

nav.scrolling-mobile-menu::-webkit-scrollbar {
    display: none; }

nav.scrolling-mobile-menu a {
    flex: 0 0 auto;
    padding-left: .5rem;
    padding-right: .5rem;
    color: #000; }

.is-facebook.button {
    background-color: #3b5998;
    color: #fff; }

.is-twitter.button {
    background-color: #00acee;
    color: #fff; }

.no-bg {
    background-color: transparent !important; }

.width-auto {
    width: auto !important; }

.border-column {
    border-radius: 7px;
    border: 1px solid $border-color;
    &.expand-on-mobile {
        @include mobile {
            border: none;
            margin-left: -1rem;
            margin-right: -1rem; } } }

.border-column .column-section {
    border-bottom: 1px solid $border-color;
    padding: 1rem 1.5rem;
    &:last-child {
        border-bottom: none; } }
.rbc-calendar * {
  font-family: $family-primary !important; }


.tag.is-secondary {
    background-color: $secondary;
    color: #fff; }

.tag.is-floating {
    position: absolute;
    z-index: 100;
    top: -1em;
    left: 100%;
    margin: 0 0 0 -1.5em!important; }

.has-box-shadow {
    box-shadow: $box-shadow; }

/* Helpscout and Beacon CSS */
.BeaconFabButtonFrame {
    z-index: 999 !important; }

.search-page .BeaconFabButtonFrame {}

.search-page .BeaconFabButtonFrame {
    bottom: 6rem !important;
    right: 20px !important; }

.has-fixed-cta .BeaconFabButtonFrame {
    bottom: 6rem !important;
    right: 20px !important; }

body.modal-open .BeaconFabButtonFrame {
    display: none !important; }

body.hide-intercom .BeaconFabButtonFrame {
    display: none !important; }

.tabs li button {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    padding: .25rem .5rem;
    border-bottom: 1px solid transparent;
    border-radius: 0px;
    margin-bottom: -1px;
    &:hover {
        border-bottom: 1px solid $dark; }
    &:focus {
        box-shadow: none !important; } }

.tabs li.is-active button {
    color: $primary !important;
    font-weight: 700 !important;
    border-bottom: 1px solid $primary; }

.is-paragraph-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #424242; }

.bike-profile-subHeading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: #424242; }

.wizard-header {
    margin-top: 10px !important;
    margin-bottom: 30px !important; }

.grecaptcha-badge {
    visibility: hidden; }
