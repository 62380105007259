.try-my-modal {
  .border-24 {
    border-radius: 24px !important; }
  .magic-dots.slick-dots {
    top: 0 !important; }
  .mobile-tryme-slider {
    width: 75%;
    margin: 0 auto; }
  .step-title {
    font-size: 1.25rem; }
  .try-me-icon {
    width: 48px; }
  .step-action {
    font-size: 1.25rem !important;
    width: 75%; } }

.bg-grey {
    background-color: #f2f2f2; }

@media screen and (max-height: 740px) {
  .mobile-tryme-slider {
    width: 65% !important; } }
@media screen and (max-height: 700px) {
  .mobile-tryme-slider {
    width: 60% !important; } }
@media screen and (max-height: 680px) {
  .mobile-tryme-slider {
    width: 50% !important; } }
@media screen and (max-height: 640px) {
  .mobile-tryme-slider {
    width: 50% !important; }
  .step-title {
    font-size: 1rem !important; }
  .try-me-icon {
    width: 36px !important; }
  .step-action {
    width: 50% !important; } }
@media screen and (max-height: 600px) {
  .mobile-tryme-slider {
    width: 45% !important; }
  .step-action {
    font-size: 1.2rem !important;
    width: 60% !important; } }
