$label-color: $text-strong !default;
$label-weight: $weight-bold !default;

$help-size: $size-small !default;

$label-colors: $form-colors !default;

.label {
  color: $label-color;
  display: block;
  font-size: $size-normal;
  font-weight: $label-weight;
  &:not(:last-child) {
    margin-bottom: 0.5em; }
  // Sizes
  &.is-small {
    font-size: $size-small; }
  &.is-medium {
    font-size: $size-medium; }
  &.is-large {
    font-size: $size-large; } }

.help {
  display: block;
  font-size: $help-size;
  margin-top: 0.25rem;
  @each $name, $pair in $label-colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      color: $color; } } }

// Containers

.field {
  &:not(:last-child) {
    margin-bottom: 0.75rem; }
  // Modifiers
  &.has-addons {
    display: flex;
    justify-content: flex-start;
    .control {
      &:not(:last-child) {
        @include ltr-property("margin", -1px); }
      &:not(:first-child):not(:last-child) {
        .button,
        .input,
        .select select {
          border-radius: 0; } }
      &:first-child:not(:only-child) {
        .button,
        .input,
        .select select {
          @include ltr {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; }
          @include rtl {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; } } }
      &:last-child:not(:only-child) {
        .button,
        .input,
        .select select {
          @include ltr {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0; }
          @include rtl {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0; } } }
      .button,
      .input,
      .select select {
        &:not([disabled]) {
          &:hover,
          &.is-hovered {
            z-index: 2; }
          &:focus,
          &.is-focused,
          &:active,
          &.is-active {
            z-index: 3;
            &:hover {
              z-index: 4; } } } }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 1; } }
    &.has-addons-centered {
      justify-content: center; }
    &.has-addons-right {
      justify-content: flex-end; }
    &.has-addons-fullwidth {
      .control {
        flex-grow: 1;
        flex-shrink: 0; } } }
  &.is-grouped {
    display: flex;
    justify-content: flex-start;
    & > .control {
      flex-shrink: 0;
      &:not(:last-child) {
        margin-bottom: 0;
        @include ltr-property("margin", 0.75rem); }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 1; } }
    &.is-grouped-centered {
      justify-content: center; }
    &.is-grouped-right {
      justify-content: flex-end; }
    &.is-grouped-multiline {
      flex-wrap: wrap;
      & > .control {
        &:last-child,
        &:not(:last-child) {
          margin-bottom: 0.75rem; } }
      &:last-child {
        margin-bottom: -0.75rem; }
      &:not(:last-child) {
        margin-bottom: 0; } } }
  &.is-horizontal {
    @include tablet {
      display: flex; } } }

.field-label {
  .label {
    font-size: inherit; }
  @include mobile {
    margin-bottom: 0.5rem; }
  @include tablet {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    @include ltr-property("margin", 1.5rem);
    text-align: right;
    &.is-small {
      font-size: $size-small;
      padding-top: 0.375em; }
    &.is-normal {
      padding-top: 0.375em; }
    &.is-medium {
      font-size: $size-medium;
      padding-top: 0.375em; }
    &.is-large {
      font-size: $size-large;
      padding-top: 0.375em; } } }

.field-body {
  .field .field {
    margin-bottom: 0; }
  @include tablet {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1;
    .field {
      margin-bottom: 0; }
    & > .field {
      flex-shrink: 1;
      &:not(.is-narrow) {
        flex-grow: 1; }
      &:not(:last-child) {
        @include ltr-property("margin", 0.75rem); } } } }

.control {
  box-sizing: border-box;
  clear: both;
  font-size: $size-normal;
  position: relative;
  text-align: inherit;
  // Modifiers
  &.has-icons-left,
  &.has-icons-right {
    .input,
    .select {
      &:focus {
        & ~ .icon {
          color: $input-icon-active-color; } }
      &.is-small ~ .icon {
        font-size: $size-small; }
      &.is-medium ~ .icon {
        font-size: $size-medium; }
      &.is-large ~ .icon {
        font-size: $size-large; } }
    .icon {
      color: $input-icon-color;
      height: $input-height;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: $input-height;
      z-index: 4; } }
  &.has-icons-left {
    .input,
    .select select {
      padding-left: $input-height; }
    .icon.is-left {
      left: 0; } }
  &.has-icons-right {
    .input,
    .select select {
      padding-right: $input-height; }
    .icon.is-right {
      right: 0; } }
  &.is-loading {
    &::after {
      @extend %loader;
      position: absolute !important;
      @include ltr-position(0.625em);
      top: 0.625em;
      z-index: 4; }
    &.is-small:after {
      font-size: $size-small; }
    &.is-medium:after {
      font-size: $size-medium; }
    &.is-large:after {
      font-size: $size-large; } }
  &.events-icon, {
    .icon {
      left: 6.5px !important;
      top: 6px !important; } } }
.marker-icon {
    left: 11.5px !important;
    top: 13px !important; }
.search-bar-icons-calendar {
  .icon {
    left: 6.5px !important;
    top: 3.25px !important; } }
.location-icon {
    left: 14px !important;
    top: 6.25px !important;
    @include mobile {
      left: 9px !important;
      top: 0px !important; } }
.calendar-icon {
  .icon {
    left: 6.75px !important;
    top: 6.75px !important; } }
