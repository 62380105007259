.klaviyo-desktop-banner-added.search-page .klaviyo-form {
  position: fixed;
  width: 100%;
  top: 72px;
}

.klaviyo-form {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important
}

body.klaviyo-mobile-banner-added.search-page {
  overflow-x: unset;
}

.klaviyo-desktop-banner-added .search-left-inner {
  padding-top: 13.4rem !important;
}

.klaviyo-desktop-banner-added .search-filters-bar {
  box-shadow: none !important;
}

.klaviyo-form strong {
  color: #fff;
}

.home-klaviyo-banner .home-page-cta {
  margin-top: 2.4rem;
}

@media (max-width: 768px) {
  .home-klaviyo-banner .home-page-cta {
    margin-top: 5.4rem;
  }
}